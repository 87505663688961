const variables = {
  local: {
    nodeEnv: "local",
    apiUrl: "http://localhost:3000",
    cryptoAesKey: "58re97235srasdf24sdfaa2fe03ec434c0a911182",
    loginUrl: "http://localhost:6970",
    skyviewsUrl: "https://s3.amazonaws.com/dev.skyviews.console.pascalsoftware.com",
  },
  development: {
    nodeEnv: "development",
    apiUrl: "https://dev-api.console.pascalsoftware.com",
    cryptoAesKey: "58re97235srasdf24sdfaa2fe03ec434c0a911182",
    loginUrl: "https://dev.console.pascalsoftware.com",
    skyviewsUrl: "https://s3.amazonaws.com/dev.skyviews.console.pascalsoftware.com",
  },
  stage: {
    nodeEnv: "stage",
    apiUrl: "https://stage-api.console.pascalsoftware.com",
    cryptoAesKey: "58re97235srasdf24sdfaa2fe03ec434c0a911182",
    loginUrl: "https://stage.console.pascalsoftware.com",
    skyviewsUrl: "https://s3.amazonaws.com/stage.skyviews.console.pascalsoftware.com",
  },
  production: {
    nodeEnv: "production",
    apiUrl: "https://api.console.pascalsoftware.com",
    cryptoAesKey: "58re97235ddda2fe03ec434c0a911182",
    loginUrl: "https://console.pascalsoftware.com",
    skyviewsUrl: "https://s3.amazonaws.com/skyviews.console.pascalsoftware.com",
  },
};
export default process?.env?.REACT_APP_ENVIRONMENT
  ? variables[process?.env?.REACT_APP_ENVIRONMENT]
  : {};
