import {  getPublicLogisticShipmentDetails } from "apis/shipments";
import Loading from "components/Loading";
import PascalLogo from "components/svgs/PascalLogo";
import { isEmpty, mapValues } from "lodash";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { FAVICON_URL, PAGE_NOT_FOUND_URL } from "scripts/constants";
import { getApiKey } from "scripts/helpers";
import ShipmentDetailsView from "./ShipmentLogisticDetailsView";

const ShipmentDetailsContainer = () => {
  const { shipmentPublicKey = ``, organizationPublicKey = `` } = useParams();

  let organizationConfigReqObj: any = {
    shipmentPublicKey,
  };

  if (organizationPublicKey) {
    organizationConfigReqObj = {
      ...organizationConfigReqObj,
      organizationPublicKey,
    };
  }

  const { data: shipmentDetailData, isLoading }: any = useQuery(
    getApiKey("getPublicLogisticShipmentDetails", organizationConfigReqObj),
    () => getPublicLogisticShipmentDetails(organizationConfigReqObj),
    {
      onSuccess: (data) => {
        if (!isEmpty(data?.organizationConfig)) {
          const root: any = document.querySelector(":root");

          mapValues(data?.organizationConfig?.primaryColor ?? {}, (value, key) => {
            root.style.setProperty(`--primary-${key}`, value);
          });

          mapValues(data?.organizationConfig?.primaryTextColor ?? {}, (value, key) => {
            root.style.setProperty(`--primary-text-${key}`, value);
          });

          mapValues(data?.organizationConfig?.secondaryColor ?? {}, (value, key) => {
            root.style.setProperty(`--secondary-${key}`, value);
          });

          mapValues(data?.organizationConfig?.secondaryTextColor ?? {}, (value, key) => {
            root.style.setProperty(`--secondary-text-${key}`, value);
          });

          root.style.setProperty(
            `--header--background--color`,
            data?.organizationConfig?.headerBackgroundColor,
          );
        }
      },
    },
  );

  useEffect(() => {
    if (shipmentDetailData && isEmpty(shipmentDetailData?.shipmentDetail)) {
      window.location.replace(PAGE_NOT_FOUND_URL);
    }

    if (!isEmpty(shipmentDetailData?.organizationConfig)) {
      let link: any = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }

      if (shipmentDetailData?.organizationConfig?.favicon) {
        link.href = shipmentDetailData?.organizationConfig?.favicon;
      } else {
        link.href = FAVICON_URL;
      }
    }
  }, [shipmentDetailData]);

  if (isLoading || (shipmentDetailData && isEmpty(shipmentDetailData?.shipmentDetail)))
    return <Loading />;
  
  return (
    <>
      <Helmet>
        <title>Public Shipment {`${shipmentDetailData?.shipmentDetail?.shipmentTrackingId ?? ""}`}</title>
      </Helmet>
      <div className="flex flex-col bg-[#FAFAFD] h-full">
        <div className="flex p-[16px] bg-headerBackgroundColor">
          {shipmentDetailData?.organizationConfig?.logo ? (
            <img style={{ height: 28 }} src={shipmentDetailData?.organizationConfig?.logo} />
          ) : (
            <PascalLogo />
          )}
        </div>
        <div className="flex flex-col p-0 tablet:p-[16px]">
          <ShipmentDetailsView
            {...{
              shipmentDetails: shipmentDetailData?.shipmentDetail ?? {},
              isLoadingShipmentDetails: isLoading,
              isLoadingContainers: isLoading,
            }}
          />{" "}
        </div>
      </div>
    </>
  );
};

export default ShipmentDetailsContainer;
